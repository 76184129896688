import { type ReactNode, useEffect, useId, useRef, useState } from 'react';
import { matchPath } from 'react-router';
import styled, { css } from 'styled-components';
import { useLocation, useNavigate } from '../../contexts/UIContext';
import {
  DESKTOP_MAIN_TABS_HORIZONTAL_GAP,
  SIZING_0_5,
  SIZING_5_5,
  SPACING_1,
  SPACING_2,
  SPACING_3,
} from '../../theme';
import media from '../utils/media';

const Wrapper = styled.div<{ $noHeaderContentSpacing?: boolean }>`
  background: inherit;
  margin-bottom: ${({ $noHeaderContentSpacing }) => ($noHeaderContentSpacing ? 0 : SPACING_3)};
`;

export const TabHeader = styled.div<{ $sticky?: boolean }>`
  ${props =>
    props.$sticky &&
    css`
      position: sticky;
      z-index: 3;
      top: 0;
      background: inherit;
    `}
`;

const Filter = styled.div`
  padding: ${SPACING_2} 0;
  ${media.lessThan('tablet')} {
    padding: ${SPACING_1} 0;
  }
`;

export const TabList = styled.div<{
  $variant: 'subTabs' | 'mainTabs';
  $fullWidth?: boolean;
}>`
  display: ${props => (props.$variant === 'mainTabs' || props.$fullWidth ? 'flex' : 'inline-flex')};
  justify-content: ${props => (props.$fullWidth ? 'stretch' : 'flex-start')};
  overflow-x: auto;
  ${props =>
    props.$variant === 'subTabs'
      ? css`
          border: 1px solid ${props.theme.colorBorderSubTabDefault};
          border-radius: ${props.theme.radiusSubTabs};
          background: ${props.theme.colorBackgroundSubTabInactive};
        `
      : css`
          border-bottom: 1px solid ${props.theme.colorBorderDividerDefault};
          gap: ${props.$fullWidth ? false : DESKTOP_MAIN_TABS_HORIZONTAL_GAP};
        `}
  height: ${props =>
    props.$variant === 'subTabs'
      ? props.theme.sizingSubTabsHeight
      : props.theme.sizingMainTabItemHeight};

  > button,
  > a {
    cursor: pointer;
    height: 100%;
    flex-shrink: 1;
    min-width: ${SIZING_5_5};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: ${props => props.$fullWidth && '1'};
    border: 0;

    ${props =>
      props.$variant === 'subTabs'
        ? css`
            border-radius: ${props.theme.radiusSubTabItem};
            color: ${props => props.theme.colorTextSubTabItemInactive};
            padding: 0 1rem;

            &:hover {
              color: ${props.theme.colorTextSubTabItemHover};
              background: ${props.theme.colorBackgroundSubTabHover};
            }
          `
        : css`
            border-bottom: ${SIZING_0_5} solid transparent;
            color: ${props.theme.colorTextMainTabItemInactive};
            padding: ${props.$fullWidth && '0 0.5rem'};
            &:hover {
            }
          `}
    font-weight: 500;
    background: inherit;
    white-space: nowrap;
    &[aria-selected="true"],
    &[aria-current="page"] {
      ${props =>
        props.$variant === 'subTabs'
          ? css`
              color: ${props => props.theme.colorTextSubTabItemActive};
              background: ${props => props.theme.colorBackgroundSubTabActive};
            `
          : css`
              color: ${props => props.theme.colorTextMainTabItemActive};
              border-bottom: ${SIZING_0_5} solid
                ${props => props.theme.colorBorderMainTabItemActive};
            `}
      pointer-events: none;
    }
  }
`;

interface TabCommon {
  title: string;
  dataTestId?: string;
}

type LinkTab = TabCommon & {
  href: string;
  renderContent?: undefined;
  renderFilter?: undefined;
};

type ButtonTab = TabCommon & {
  href?: undefined;
  renderContent: () => ReactNode;
  renderFilter?: () => ReactNode;
};
const tabIsButtonTab = (tab: LinkTab | ButtonTab): tab is ButtonTab => !!tab.renderContent;

export const Tabs = ({
  tabs,
  activeTab,
  setActiveTab,
  variant,
  fullWidth,
  stickyHeader,
  noHeaderContentSpacing,
}: {
  variant: 'subTabs' | 'mainTabs';
  tabs: LinkTab[] | ButtonTab[];
  fullWidth?: boolean;
  stickyHeader?: boolean;
  noHeaderContentSpacing?: boolean;
} & (
  | {
      activeTab: number;
      setActiveTab: (t: number) => void;
    }
  | {
      activeTab?: undefined;
      setActiveTab?: undefined;
    }
)) => {
  const navigate = useNavigate();
  const location = useLocation();
  const prefix = useId();
  const [uncontrolledSelectedTab, setUncontrolledSelectedTab] = useState(0);
  const selectedTab = typeof activeTab === 'number' ? activeTab : uncontrolledSelectedTab;
  const setSelectedTab =
    typeof setActiveTab === 'function' ? setActiveTab : setUncontrolledSelectedTab;
  const tabRefs = useRef<Array<HTMLButtonElement>>([]);

  const linkMode = !!tabs[0]?.href;

  const stripTrailingSlash = (url: string) => url.replace(/\/$/, '');
  const getAriaCurrentPage = (url: string) => {
    if (location.hash === url) return 'page';

    const path = stripTrailingSlash(url.split('?')[0]);
    const currentPath = stripTrailingSlash(location.pathname);

    return matchPath(path, currentPath) ? 'page' : undefined;
  };

  useEffect(() => {
    tabRefs.current = tabRefs.current.slice(0, tabs.length);
  }, [tabs]);

  return (
    <Wrapper $noHeaderContentSpacing={noHeaderContentSpacing}>
      <TabHeader $sticky={stickyHeader}>
        <TabList
          $fullWidth={fullWidth}
          $variant={variant}
          role="tablist"
          onKeyDown={
            linkMode
              ? undefined
              : e => {
                  if (e.key === 'ArrowLeft') {
                    const newSelectedTab = Math.max(selectedTab - 1, 0);
                    setSelectedTab(newSelectedTab);
                    tabRefs.current[newSelectedTab]?.focus();
                  } else if (e.key === 'ArrowRight') {
                    const newSelectedTab = Math.min(selectedTab + 1, tabs.length - 1);
                    setSelectedTab(newSelectedTab);
                    tabRefs.current[newSelectedTab]?.focus();
                  }
                }
          }
        >
          {tabs.map((tab, i) =>
            tab.href ? (
              <a
                href={tab.href}
                data-testid={tab.dataTestId}
                key={i}
                aria-current={getAriaCurrentPage(tab.href)}
                onClick={e => {
                  e.preventDefault();
                  navigate(tab.href);
                }}
              >
                {tab.title}
              </a>
            ) : (
              <button
                key={i}
                type="button"
                role="tab"
                data-testid={tab.dataTestId}
                ref={el => {
                  if (el) {
                    tabRefs.current[i] = el;
                  }
                }}
                tabIndex={selectedTab === i ? 0 : -1}
                id={`${prefix}-tab-${i}`}
                aria-controls={`${prefix}-tabpanel-${i}`}
                aria-selected={selectedTab === i}
                onClick={() => setSelectedTab(i)}
              >
                {tab.title}
              </button>
            ),
          )}
        </TabList>
        {tabs[selectedTab]?.renderFilter ? (
          <Filter>{tabs[selectedTab].renderFilter?.()}</Filter>
        ) : null}
      </TabHeader>

      {!linkMode && (
        <div>
          {tabs.filter(tabIsButtonTab).map((tab, i) => (
            <div
              key={i}
              role="tabpanel"
              aria-labelledby={`${prefix}-tab-${i}`}
              id={`${prefix}-tabpanel-${i}`}
              hidden={selectedTab !== i}
            >
              {selectedTab === i ? tab.renderContent() : null}
            </div>
          ))}
        </div>
      )}
    </Wrapper>
  );
};
