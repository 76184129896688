import {
  DANGER_100,
  DANGER_500,
  DANGER_700,
  DANGER_800,
  GREY_100,
  GREY_200,
  GREY_300,
  GREY_400,
  GREY_500,
  GREY_600,
  GREY_700,
  GREY_800,
  GREY_900,
  GREY_1000,
  NEUTRAL_WHITE,
  PURPLE_100,
  PURPLE_200,
  PURPLE_400,
  PURPLE_500,
  PURPLE_600,
  PURPLE_700,
  PURPLE_800,
  PURPLE_900,
  RADIUS_2XL,
  RADIUS_LG,
  RADIUS_MD,
  RADIUS_SM,
  RED_200,
  RED_300,
  RED_700,
  RED_800,
  SIZING_1,
  SIZING_1_5,
  SIZING_2,
  SIZING_2_5,
  SIZING_3,
  SIZING_4,
  SIZING_5,
  SIZING_5_5,
  SIZING_6,
  SIZING_7,
  SIZING_8,
  SIZING_9,
  SIZING_11,
  SIZING_13,
  SIZING_14,
  SUCCESS_100,
  SUCCESS_500,
  SUCCESS_700,
  SUCCESS_800,
  TEAL_300,
  TEAL_700,
  TEAL_800,
  TEAL_900,
  WARNING_100,
  WARNING_400,
  WARNING_500,
  WARNING_600,
  WARNING_700,
  WARNING_800,
} from '.';

declare module 'styled-components' {
  type MndDefaultTheme = typeof defaultTheme;
  export interface DefaultTheme extends MndDefaultTheme {} // eslint-disable-line @typescript-eslint/no-empty-interface
}

export const defaultTheme = {
  radiusModal: RADIUS_LG,
  radiusCard: RADIUS_SM,
  radiusButton: RADIUS_MD,
  colorTextHeadlinePrimary: GREY_1000,
  colorTextBodyPrimary: GREY_900,
  colorTextPlaceholderPrimary: GREY_600,
  colorBorderAlertDefault: GREY_500,
  colorBorderAlertError: DANGER_700,
  colorBackgroundAlertError: DANGER_100,
  colorBorderAlertWarning: WARNING_400,
  colorBackgroundAlertWarning: WARNING_100,
  colorBackgroundAlertInfo: PURPLE_100,
  colorIconsDefault: GREY_900,
  colorIconsInfo: PURPLE_700,
  colorIconsWarning: WARNING_500,
  colorIconsDanger: DANGER_700,
  colorIconsOnDark: NEUTRAL_WHITE,
  colorBorderAlertInfo: PURPLE_500,
  colorBackgroundAlertDefault: GREY_100,
  sizingIconXxs: SIZING_2,
  sizingIconXs: SIZING_3,
  sizingIconSm: SIZING_4,
  sizingIconMd: SIZING_6,
  sizingIconLg: SIZING_8,
  sizingIconXl: SIZING_11,
  sizingIcon2xl: SIZING_13,
  sizingIcon3xl: SIZING_14,
  colorBackgroundButtonFilledOnLightDefault: PURPLE_700,
  colorBackgroundButtonFilledOnLightHover: PURPLE_800,
  colorBackgroundButtonFilledOnLightFocused: PURPLE_900,
  colorBackgroundButtonFilledOnLightDisabled: GREY_300,
  sizingButtonHeight: SIZING_5_5,
  colorTextButtonTextFilledOnLightDefault: NEUTRAL_WHITE,
  colorTextButtonTextFilledOnLightHover: NEUTRAL_WHITE,
  colorTextButtonTextFilledOnLightFocused: NEUTRAL_WHITE,
  colorTextButtonTextFilledOnLightDisabled: GREY_600,
  colorTextButtonTextTonedOnLightDefault: GREY_900,
  colorTextButtonTextTonedOnLightHover: GREY_900,
  colorTextButtonTextTonedOnLightFocused: GREY_900,
  colorTextButtonTextTonedOnLightDisabled: GREY_600,
  colorBackgroundButtonTonedOnLightHover: GREY_400,
  colorBackgroundButtonTonedOnLightFocused: GREY_500,
  colorTextLabelPrimary: GREY_900,
  colorTextSubheadingPrimary: GREY_1000,
  colorTextDisplayPrimary: GREY_1000,
  radiusInput: RADIUS_MD,
  colorTextButtonTextTextButtonOnLightDefault: GREY_900,
  colorTextButtonTextTextButtonOnLightHover: PURPLE_700,
  colorTextButtonTextTextButtonOnLightFocused: PURPLE_700,
  radiusAlert: RADIUS_MD,
  colorTextButtonTextTextButtonOnLightDisabled: GREY_600,
  sizingAlertDefault: SIZING_8,
  colorBackgroundToggleSwitchTrueEnabled: RED_700,
  colorBackgroundCheckboxTrueEnabled: PURPLE_700,
  colorBackgroundRadioButtonTrueEnabled: PURPLE_700,
  colorBackgroundRadioButtonTrueDisabled: GREY_400,
  radiusRadioButton: RADIUS_2XL,
  colorBackgroundToggleSwitchFalseEnabled: GREY_400,
  colorBorderToggleSwitchFalseEnabled: GREY_600,
  colorBackgroundCheckboxFalseEnabled: NEUTRAL_WHITE,
  colorBorderCheckboxFalseEnabled: GREY_600,
  colorBackgroundToggleSwitchTrueDisabled: RED_300,
  colorBackgroundToggleSwitchFalseDisabled: GREY_200,
  colorBackgroundCheckboxTrueDisabled: GREY_400,
  colorBackgroundCheckboxFalseDisabled: NEUTRAL_WHITE,
  colorTextBodyDisabled: GREY_500,
  colorTextLabelDisabled: GREY_500,
  colorBackgroundRadioButtonFalseEnabled: NEUTRAL_WHITE,
  colorBackgroundRadioButtonFalseDisabled: NEUTRAL_WHITE,
  sizingRadioButtonMain: SIZING_2,
  sizingCheckboxHeight: SIZING_2,
  sizingToggleSwitchMainHeight: SIZING_2,
  sizingToggleSwitchMainWidth: SIZING_4,
  sizingToggleSwitchKnobHeight: SIZING_1_5,
  sizingToggleSwitchKnobWidth: SIZING_1_5,
  sizingRadioButtonKnob: SIZING_1,
  colorBorderToggleSwitchFalseDisabled: GREY_400,
  colorBorderRadioButtonFalseEnabled: GREY_600,
  colorBorderRadioButtonFalseDisabled: GREY_400,
  colorBorderCheckboxFalseDisabled: GREY_400,
  sizingSettingsRowDefault: SIZING_3,
  sizingButtonWidthMin: SIZING_9,
  sizingButtonWidthIconOnly: SIZING_5_5,
  colorBackgroundContainerLight: GREY_200,
  radiusToggleSwitch: RADIUS_2XL,
  radiusCheckbox: RADIUS_SM,
  radiusChipShort: RADIUS_2XL,
  radiusBadge: RADIUS_2XL,
  sizingCheckboxWidth: SIZING_2,
  radiusGuideBannerMaxi: RADIUS_MD,
  colorBackgroundGuideBannerDefault: PURPLE_200,
  colorTextLinkOnLight: PURPLE_700,
  sizingSystemInfoHeight: SIZING_7,
  colorTextLinkOnDark: NEUTRAL_WHITE,
  colorTextBodyOnDark: NEUTRAL_WHITE,
  colorBackgroundSystemInfoDefault: PURPLE_700,
  colorTextAlertActionDefault: GREY_900,
  colorTextAlertActionInfo: GREY_900,
  colorTextAlertActionWarning: GREY_900,
  colorTextAlertActionError: GREY_900,
  colorTextModeIndicatorDefault: RED_700,
  colorTextModeIndicatorAssistance: '#FFFFFF',
  colorTextMenuItemDefault: GREY_800,
  colorTextMenuItemSelected: RED_700,
  colorBackgroundNotificationDotDefault: RED_700,
  radiusToast: RADIUS_MD,
  colorBackgroundToastWarning: NEUTRAL_WHITE,
  colorBackgroundToastError: NEUTRAL_WHITE,
  colorBackgroundToastSuccess: NEUTRAL_WHITE,
  colorIconsSuccess: SUCCESS_700,
  sizingToastWidth: '25rem',
  colorTextToastTextSuccess: GREY_900,
  colorTextToastTextWarning: GREY_900,
  colorTextToastTextError: GREY_900,
  radiusPopover: RADIUS_LG,
  colorBackgroundContainerDark: GREY_1000,
  colorBackgroundPopoverDefault: NEUTRAL_WHITE,
  colorBackgroundPopoverDark: GREY_1000,
  colorTextHeadlineOnDark: NEUTRAL_WHITE,
  radiusTooltip: RADIUS_SM,
  colorTextTooltipDefault: NEUTRAL_WHITE,
  colorBackgroundTooltipDefault: GREY_900,
  colorTextInputLabelEmptyEnabledDefault: GREY_700,
  colorTextInputLabelEmptyHoverDefault: GREY_900,
  colorTextInputLabelEmptyFocusedDefault: PURPLE_700,
  colorTextInputLabelPopulatedEnabledDefault: GREY_900,
  colorTextInputLabelPopulatedHoverDefault: GREY_900,
  colorTextInputLabelPopulatedFocusedDefault: PURPLE_700,
  colorTextInputInputPopulatedEnabled: GREY_900,
  colorTextInputInputPopulatedHover: GREY_900,
  colorTextInputInputPopulatedFocused: GREY_900,
  colorTextInputHelperTextDefault: GREY_800,
  colorTextInputErrorMessageDefault: DANGER_700,
  colorTextInputLabelEmptyFocusedError: DANGER_700,
  colorTextInputLabelPopulatedEnabledError: DANGER_700,
  colorTextInputLabelPopulatedHoverError: DANGER_700,
  colorTextInputLabelPopulatedFocusedError: DANGER_700,
  colorTextInputLabelEmptyEnabledError: DANGER_700,
  colorTextInputLabelEmptyHoverError: DANGER_700,
  colorBorderInputEmptyEnabledDefault: GREY_700,
  colorBorderInputEmptyHoverDefault: GREY_900,
  colorBorderInputEmptyFocusedDefault: PURPLE_700,
  colorBorderInputPopulatedEnabledDefault: GREY_700,
  colorBorderInputPopulatedHoverDefault: GREY_900,
  colorBorderInputPopulatedFocusedDefault: PURPLE_700,
  colorBorderInputEmptyEnabledError: DANGER_700,
  colorBorderInputEmptyHoverError: DANGER_700,
  colorBorderInputEmptyFocusedError: DANGER_700,
  colorBorderInputPopulatedEnabledError: DANGER_700,
  colorBorderInputPopulatedHoverError: DANGER_700,
  colorBorderInputPopulatedFocusedError: DANGER_700,
  colorTextMainTabItemInactive: GREY_800,
  colorTextMainTabItemActive: RED_700,
  colorTextSubTabItemInactive: GREY_600,
  colorTextSubTabItemActive: GREY_900,
  colorBorderDividerDefault: GREY_400,
  colorBorderMainTabItemActive: RED_700,
  colorBackgroundSubTabInactive: GREY_200,
  colorBackgroundSubTabActive: NEUTRAL_WHITE,
  sizingMainTabItemHeight: SIZING_6,
  sizingSubTabsHeight: SIZING_6,
  sizingSubTabItemHeight: SIZING_6,
  colorIconsNavigationPrimary: RED_700,
  radiusSubTabs: RADIUS_SM,
  radiusSubTabItem: RADIUS_SM,
  colorIconsNavigationSecondary: GREY_900,
  radiusFab: RADIUS_2XL,
  radiusFabRow: RADIUS_2XL,
  colorTextFabLabelDefault: NEUTRAL_WHITE,
  colorTextFabLabelHover: NEUTRAL_WHITE,
  colorBackgroundFabDefault: PURPLE_700,
  colorBackgroundFabHover: PURPLE_800,
  colorBackgroundFabRowDefault: GREY_300,
  radiusStepperItemComponent: RADIUS_2XL,
  colorBackgroundStepperItemComponentInactive: GREY_500,
  colorBackgroundStepperItemComponentHover: GREY_700,
  colorBackgroundStepperItemComponentActive: RED_700,
  colorTextStepperItemComponentInactive: NEUTRAL_WHITE,
  colorTextStepperItemComponentHover: NEUTRAL_WHITE,
  colorTextStepperItemComponentActive: NEUTRAL_WHITE,
  colorTextStepperItemComponentStepperItemLabelInactive: GREY_500,
  colorTextStepperItemComponentStepperItemLabelHover: GREY_900,
  colorTextStepperItemComponentStepperItemLabelActive: GREY_900,
  sizingStepperItemComponentHeight: SIZING_3,
  sizingStepperItemComponentWidth: SIZING_3,
  radiusProgressBarProgress: RADIUS_2XL,
  radiusProgressBarBackground: RADIUS_2XL,
  colorTextProgressBarLabelDefault: GREY_900,
  colorBackgroundProgressBarProgressDefault: PURPLE_700,
  colorBackgroundProgressBarBackgroundDefault: GREY_400,
  colorBackgroundSpinnerOnLightDefault: PURPLE_700,
  radiusGuideBannerMini: RADIUS_2XL,
  colorBackgroundButtonTonedOnLightDefault: GREY_300,
  colorBackgroundButtonTonedOnLightDisabled: GREY_300,
  colorTextButtonTextTextButtonOnDarkDefault: NEUTRAL_WHITE,
  colorTextButtonTextTextButtonOnDarkHover: PURPLE_500,
  colorTextButtonTextTextButtonOnDarkFocused: PURPLE_500,
  colorTextButtonTextTextButtonOnDarkDisabled: GREY_800,
  sizingInputTextHeight: SIZING_5_5,
  colorTextGuideBannerHeadingDefault: PURPLE_900,
  radiusGuideBannerMidi: RADIUS_2XL,
  colorTextAssistanceBannerBodyDefault: TEAL_800,
  colorBackgroundAssistanceBannerDefault: TEAL_300,
  colorBackgroundRadioButtonTrueFocused: PURPLE_800,
  colorBackgroundRadioButtonFalseHover: NEUTRAL_WHITE,
  colorBorderRadioButtonFalseHover: GREY_700,
  colorBackgroundToggleSwitchTrueHover: RED_800,
  colorBackgroundToggleSwitchFalseHover: GREY_400,
  colorBorderToggleSwitchFalseHover: GREY_700,
  colorBackgroundCheckboxTrueHover: PURPLE_800,
  colorBackgroundCheckboxFalseHover: NEUTRAL_WHITE,
  colorBorderCheckboxFalseHover: GREY_700,
  colorBackgroundButtonFilledOnLightLoading: GREY_300,
  colorBackgroundButtonTonedOnLightLoading: GREY_300,
  colorBackgroundStepperMiniItemOnLightDefault: GREY_400,
  colorBackgroundStepperMiniItemOnLightActive: PURPLE_700,
  colorTextAssistanceBannerHeadingDefault: TEAL_900,
  colorTextGuideBannerBodyDefault: PURPLE_800,
  colorTextGuideBannerActionDefault: PURPLE_700,
  colorTextAssistanceBannerActionDefault: TEAL_700,
  colorIconsGuideBannerDefault: PURPLE_700,
  colorIconsAssistanceBannerDefault: TEAL_700,
  colorTextButtonTextFilledOnDarkDefault: NEUTRAL_WHITE,
  colorTextButtonTextFilledOnDarkHover: NEUTRAL_WHITE,
  colorTextButtonTextFilledOnDarkFocused: NEUTRAL_WHITE,
  colorTextButtonTextFilledOnDarkDisabled: GREY_800,
  colorTextButtonTextTonedOnDarkDefault: NEUTRAL_WHITE,
  colorBackgroundButtonFilledOnDarkDefault: PURPLE_600,
  colorBackgroundButtonFilledOnDarkHover: PURPLE_500,
  colorBackgroundButtonFilledOnDarkFocused: PURPLE_400,
  colorBackgroundButtonFilledOnDarkDisabled: GREY_900,
  colorBackgroundButtonFilledOnDarkLoading: GREY_900,
  colorBackgroundButtonTonedOnDarkDefault: GREY_900,
  colorBackgroundButtonTonedOnDarkHover: GREY_800,
  colorBackgroundButtonTonedOnDarkFocused: GREY_700,
  colorBackgroundButtonTonedOnDarkDisabled: GREY_900,
  colorBackgroundButtonTonedOnDarkLoading: GREY_900,
  colorBackgroundSpinnerOnDarkDefault: NEUTRAL_WHITE,
  colorTextButtonTextTonedOnDarkHover: NEUTRAL_WHITE,
  colorTextButtonTextTonedOnDarkFocused: NEUTRAL_WHITE,
  colorTextButtonTextTonedOnDarkDisabled: GREY_800,
  colorTextToastTextLoading: GREY_900,
  colorBackgroundStepperMiniItemOnLightHover: GREY_500,
  colorBackgroundToastLoading: NEUTRAL_WHITE,
  colorBackgroundScrimDefault: '#363645',
  colorBorderSubTabDefault: GREY_500,
  colorBackgroundSubTabHover: GREY_100,
  colorTextSubTabItemHover: GREY_700,
  colorBackgroundStepperMiniItemOnDarkDefault: GREY_900,
  colorBackgroundStepperMiniItemOnDarkActive: NEUTRAL_WHITE,
  colorBackgroundStepperMiniItemOnDarkHover: GREY_700,
  radiusDialog: RADIUS_LG,
  colorBackgroundDialogDefault: NEUTRAL_WHITE,
  radiusInfoPopover: RADIUS_LG,
  radiusInfoModalSm: RADIUS_LG,
  radiusInfoModalLg: RADIUS_LG,
  sizingInfoPopoverWidth: '25rem',
  sizingInfoModalSmWidth: '25rem',
  sizingInfoModalLgWidth: '64rem',
  colorTextBadgeOnLightCommercial: RED_700,
  colorTextBadgeOnLightSecondary: PURPLE_600,
  colorTextBadgeOnLightSuccess: SUCCESS_800,
  colorTextBadgeOnLightWarning: WARNING_800,
  colorTextBadgeOnLightDanger: DANGER_800,
  colorTextBadgeOnLightNeutral: GREY_800,
  colorBackgroundBadgeRegularOnLightCommercial: RED_200,
  colorBackgroundBadgeRegularOnLightInformational: PURPLE_200,
  colorBackgroundBadgeRegularOnLightSuccess: SUCCESS_100,
  colorBackgroundBadgeRegularOnLightWarning: WARNING_100,
  colorBackgroundBadgeRegularOnLightDanger: DANGER_100,
  colorBackgroundBadgeRegularOnLightNeutral: GREY_300,
  colorTextBadgeOnDarkCommercial: NEUTRAL_WHITE,
  colorTextBadgeOnDarkInformational: NEUTRAL_WHITE,
  colorTextBadgeOnDarkSuccess: NEUTRAL_WHITE,
  colorTextBadgeOnDarkWarning: NEUTRAL_WHITE,
  colorTextBadgeOnDarkDanger: NEUTRAL_WHITE,
  colorTextBadgeOnDarkNeutral: NEUTRAL_WHITE,
  colorBackgroundBadgeRegularOnDarkCommercial: RED_700,
  colorBackgroundBadgeRegularOnDarkInformational: PURPLE_600,
  colorBackgroundBadgeRegularOnDarkSuccess: SUCCESS_700,
  colorBackgroundBadgeRegularOnDarkWarning: WARNING_700,
  colorBackgroundBadgeRegularOnDarkDanger: DANGER_700,
  colorBackgroundBadgeRegularOnDarkNeutral: GREY_700,
  radiusAvatarContact: RADIUS_2XL,
  sizingAvatarContactSm: SIZING_3,
  sizingAvatarContactMd: SIZING_6,
  sizingAvatarContactLg: SIZING_8,
  sizingAvatarContactXl: SIZING_11,
  sizingAvatarContactXs: SIZING_2,
  radiusAvatarMediaOutletXs: RADIUS_SM,
  colorTextAvatarContact: PURPLE_500,
  colorBackgroundAvatarContact: PURPLE_200,
  sizingAvatarMediaOutletSm: SIZING_6,
  sizingAvatarMediaOutletMd: SIZING_11,
  sizingAvatarMediaOutletLg: SIZING_14,
  sizingAvatarMediaOutletXs: SIZING_2,
  radiusAvatarMediaOutletSm: RADIUS_MD,
  radiusAvatarMediaOutletMd: RADIUS_MD,
  radiusAvatarMediaOutletLg: RADIUS_MD,
  radiusStoryThumbnailXs: RADIUS_SM,
  radiusStoryThumbnailSm: RADIUS_MD,
  radiusStoryThumbnailMd: RADIUS_MD,
  radiusStoryThumbnailLg: RADIUS_MD,
  sizingInputChipHeight: SIZING_9,
  radiusChipLong: RADIUS_MD,
  colorTextChipSelectionDefault: GREY_900,
  colorBackgroundChipFilterSingleEnabledDefault: GREY_300,
  colorBackgroundChipFilterSingleEnabledHover: GREY_400,
  colorBorderChipActive: GREY_800,
  sizingChipShort: SIZING_5,
  colorBackgroundAvatarMediaOutlet: GREY_200,
  colorBackgroundAvatarStoryThumbnail: GREY_200,
  sizingBadgeRegularHeight: SIZING_2_5,
  colorTextChipSelectionDisabled: GREY_700,
  colorIconsChipDefault: GREY_900,
  colorIconsChipDisabled: GREY_700,
  colorTextChipReadOnlyDefault: GREY_900,
  colorTextChipSuggestionDefault: GREY_900,
  colorTextChipSuggestionHover: GREY_1000,
  colorTextChipFilterDefault: GREY_900,
  colorTextChipFilterHover: GREY_1000,
  colorTextChipFilterMultipleDefault: GREY_900,
  colorTextChipFilterMultipleHover: GREY_1000,
  colorTextChipFilterSingleEnabledDefault: GREY_900,
  colorTextChipFilterSingleEnabledHover: GREY_1000,
  colorTextChipFilterSingleSelectedDefault: NEUTRAL_WHITE,
  colorTextChipFilterSingleSelectedHover: NEUTRAL_WHITE,
  colorBackgroundChipReadOnlyDefault: GREY_300,
  colorBackgroundChipFilterMultipleDefault: GREY_300,
  colorBackgroundChipFilterMultipleHover: GREY_400,
  colorBackgroundChipFilterDefault: GREY_300,
  colorBackgroundChipFilterHover: GREY_400,
  colorBackgroundChipSelectionDefault: GREY_300,
  colorBackgroundChipSuggestionDefault: GREY_300,
  colorBackgroundChipSuggestionHover: GREY_400,
  colorBackgroundChipFilterSingleSelectedDefault: RED_700,
  colorBackgroundChipFilterSingleSelectedHover: RED_800,
  colorTextChipSuggestionDisabled: GREY_700,
  colorTextChipFilterDisabled: GREY_700,
  colorTextChipFilterMultipleDisabled: GREY_700,
  colorTextChipFreeTextDefault: GREY_800,
  colorBackgroundChipFreeTextDefault: GREY_200,
  colorBackgroundMiniButtonOnLightDefault: GREY_300,
  colorBackgroundMiniButtonOnLightHover: GREY_500,
  colorBackgroundMiniButtonOnLightFocused: GREY_500,
  colorBackgroundMiniButtonOnDarkDefault: GREY_900,
  colorBackgroundMiniButtonOnDarkHover: GREY_800,
  colorBackgroundMiniButtonOnDarkFocused: GREY_800,
  colorTextMiniButtonOnLight: GREY_1000,
  colorTextMiniButtonOnDark: NEUTRAL_WHITE,
  radiusMiniButton: RADIUS_2XL,
  colorBackgroundBadgeMiniCommercial: RED_700,
  colorBackgroundBadgeMiniInformational: PURPLE_600,
  colorBackgroundBadgeMiniSuccess: SUCCESS_500,
  colorBackgroundBadgeMiniWarning: WARNING_600,
  colorBackgroundBadgeMiniDanger: DANGER_500,
  colorBackgroundBadgeMiniNeutral: GREY_700,
  sizingBadgeMiniHeightWidth: SIZING_1,
  sizingIconXxxs: SIZING_1_5,
};
