import styled, { type DefaultTheme } from 'styled-components';
import * as baseColors from '../../theme/colors';
import type { Size } from './types';

type BaseColors = keyof typeof baseColors;

type ThemeDefinedIconColors = keyof Pick<
  DefaultTheme,
  | 'colorIconsDefault'
  | 'colorIconsInfo'
  | 'colorIconsWarning'
  | 'colorIconsDanger'
  | 'colorIconsOnDark'
  | 'colorIconsSuccess'
  | 'colorIconsNavigationPrimary'
>;

export type ExtendedIconColors = ThemeDefinedIconColors | BaseColors;

export const getSizeInRem = (theme: DefaultTheme, $size?: Size) => {
  switch ($size) {
    case 'xxxs':
      return theme.sizingIconXxxs;
    case 'xxs':
      return theme.sizingIconXxs;
    case 'xs':
      return theme.sizingIconXs;
    case 'sm':
      return theme.sizingIconSm;
    case 'md':
      return theme.sizingIconMd;
    case 'lg':
      return theme.sizingIconLg;
    case 'xl':
      return theme.sizingIconXl;
    case '2xl':
      return theme.sizingIcon2xl;
    case '3xl':
      return theme.sizingIcon3xl;
    default:
      return theme.sizingIconXs;
  }
};

const isBaseColor = (color: string | undefined): color is BaseColors => {
  return color ? color in baseColors : false;
};

const getColor = (theme: DefaultTheme, $color?: ExtendedIconColors) => {
  if ($color && theme[$color]) {
    return theme[$color];
  } else if ($color && isBaseColor($color)) {
    return baseColors[$color];
  }
};

const SvgIconWrapper = styled.i<{ $size?: Size; $color?: ExtendedIconColors }>`
  display: inline-block;
  vertical-align: middle;
  pointer-events: none;
  border-color: inherit;
  line-height: 1.25;
  width: ${({ theme, $size }) => getSizeInRem(theme, $size)};
  height: ${({ theme, $size }) => getSizeInRem(theme, $size)};
  position: static;
  color: ${({ theme, $color }) => getColor(theme, $color)};

  svg {
    width: 100%;
    height: 100%;
  }
`;

SvgIconWrapper.displayName = 'SvgIconWrapper';

export default SvgIconWrapper;
